import React, { Fragment } from 'react';

import ShowAllCollapsible from '~/components/core/Molecules/Collapsible/ShowAllCollapsible';
import Text from '~/components/core/TextComponents/Text';
import DocumentLink from '~/components/Documents/DocumentLink';
import type { NoteContentProps } from '~/components/Notes/NoteContent/types';
import type { NotePartProps } from '~/components/Notes/NoteMiniCard';
import useOrganization from '~/components/OrganizationContext';

const DocumentsNote: React.FC<NoteContentProps> = ({ note, isExpanded, onCollapseClick, isCollapsible, maxRows }) => {
  return isCollapsible ? (
    <ShowAllCollapsible
      visibleLines={maxRows || 1}
      showAsInlineButton
      isOpen={isExpanded}
      onCollapseClick={onCollapseClick}
    >
      <DocumentsNoteInner note={note} />
    </ShowAllCollapsible>
  ) : (
    <DocumentsNoteInner note={note} />
  );
};

interface DocumentType {
  desc: string;
  is_public_option: boolean;
}

const DocumentsNoteInner: React.FC<NotePartProps> = ({ note }) => {
  const { documentTypesDict } = useOrganization() as { documentTypesDict: { [document_type: string]: DocumentType } };
  if (!note.documents) {
    return <></>;
  }
  return (
    <>
      {note.documents.map((document) => (
        <Fragment key={document.id}>
          <div className="my-12">
            <DocumentLink text="Document details" document={document} displayLinkAsButton />
          </div>
          {document.is_removed && (
            <Text
              className="mb-12"
              colorVariant={Text.COLOR_VARIANTS.ERROR}
              weight={Text.WEIGHTS.REGULAR}
              variant={Text.VARIANTS.SM}
            >
              Document was removed from claim, Reason: {document.removed_reason}
            </Text>
          )}
          <a
            href={`/api/v1/claims/${document.claim_id}/documents/${document.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Document #{document.claim_internal_id}
          </a>
          <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
            Name: {document.document_name}
          </Text>
          <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
            Type: {documentTypesDict?.[document.type]?.desc || document.type}
          </Text>
          <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
            Summary: ${document.summary}
          </Text>
        </Fragment>
      ))}
    </>
  );
};

export default DocumentsNote;
