import React from 'react';

import { CoverageTestResult } from '~/components/AiChat/SideBarDialog/Actions/Action/CoverageTestResult';
import { RoundedPanel } from '~/components/AiChat/SideBarDialog/components/RoundedPanel/RoundedPanel';
import type { AiAction, CoverageDecisionTaskData } from '~/components/AiChat/types';
import { AI_ACTION_TYPES } from '~/components/AiChat/types';
import { reportErrorInProductionOrThrow } from '~/Utils';

import '../../MarkdownTemplate.css';

interface ActionsPanelProps {
  action: AiAction;
}

export const CoverageDecisionActionInner: React.FC<ActionsPanelProps> = ({ action }) => {
  if (!action?.task_data || action?.task_data?.task_type !== AI_ACTION_TYPES.COVERAGE_DECISION) {
    reportErrorInProductionOrThrow(new Error('Invalid action task data for CoverageDecisionAction'));
  }

  const taskData = action.task_data as CoverageDecisionTaskData;

  const { coverage_tests } = taskData;

  return (
    <RoundedPanel variant="clear">
      {coverage_tests ? (
        <div className="m-12 flex flex-col gap-12">
          {coverage_tests.map((testResult, index) => (
            <CoverageTestResult
              key={index}
              testDescription={testResult.test_description}
              testPassed={testResult.test_passed}
              issue={testResult.issue}
            />
          ))}
        </div>
      ) : null}
    </RoundedPanel>
  );
};
