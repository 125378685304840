import React, { useEffect, useState } from 'react';
import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import { useFormikContext } from 'formik';

import { useClaim } from '~/components/ClaimContainer';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Radio from '~/components/core/Atomic/Radio';
import FsButton from '~/components/core/FsWrappers/FsButton/FsButton';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import SortableTable from '~/components/core/Tables/SortableTable';
import Text from '~/components/core/TextComponents/Text';
import type {
  AppraiserTypes,
  MoiAssigneeMethodVendorSearchParams,
  MoiFormikValues,
  MoiStepProps,
  MoiVendor,
} from '~/components/exposures/moi/MoiStepper/types';
import { GENERAL_EXPOSURE_FAKE_ID } from '~/components/exposures/moi/MoiUtils';
import TextFieldFormik from '~/components/TextFieldFormik';
import MOI_VENDOR_SEARCH_DISTANCES from '~/server_shared/generated-types/MOI_VENDOR_SEARCH_DISTANCES';
import MOI_VENDOR_TYPES from '~/server_shared/generated-types/MOI_VENDOR_TYPES';

export interface SearchVendorStepProps extends MoiStepProps, MoiAssigneeMethodVendorSearchParams {}

interface MoiVendorWithId extends MoiVendor {
  id: string;
}

const SearchVendorStep: React.FC<SearchVendorStepProps> = ({
  search_title,
  search_default_values,
  setAlertMessage,
  moiMethodsReturn,
}) => {
  const [vendors, setVendors] = useState<MoiVendorWithId[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isSubmitting, values, setFieldValue } = useFormikContext<MoiFormikValues>();
  const { claim } = useClaim();
  const { moiMethodsById } = moiMethodsReturn;

  useEffect(() => {
    setFieldValue('assignee_type', 'appraiser');
    setFieldValue('vendor_name', search_default_values.name);
    setFieldValue('vendor_distance', search_default_values.radius);
    setFieldValue('vendor_type', search_default_values.vendor_type);
  }, [search_default_values, setFieldValue]);

  const columns = [
    {
      id: 'selection',
      numeric: false,
      label: '',
      specialCell: (vendor: MoiVendorWithId) => (
        <Radio
          className={values?.selectedVendor?.appraiser_code === vendor.appraiser_code ? 'text-teal-700' : ''}
          checked={values?.selectedVendor?.appraiser_code === vendor.appraiser_code}
          onChange={(e, checked) => {
            if (checked) {
              setFieldValue('selectedVendor', vendor);
            }
          }}
        />
      ),
      disableSort: true,
    },
    {
      id: 'name',
      numeric: false,
      label: 'Name',
    },
    {
      id: 'address',
      numeric: false,
      label: 'Address',
      specialCell: (row: MoiVendorWithId) =>
        [row.address.address_line, row.address.city, row.address.state, row.address.postal_code].join(', '),
    },
    {
      id: 'distance',
      numeric: true,
      label: 'Distance',
      specialCmpFunc: (row1: MoiVendorWithId, row2: MoiVendorWithId) => row1.distance > row2.distance,
    },
    {
      id: 'capacity',
      numeric: false,
      label: 'Capacity',
    },
  ];

  const handleSearchClick = async () => {
    try {
      setIsLoading(true);
      setFieldValue('selectedVendor', undefined);
      const { data }: AxiosResponse<{ vendors: MoiVendor[] }> = await axios.post(
        `/api/v1/claims/${claim.id}/method_of_inspection/search_vendor`,
        {
          radius: values.vendor_distance,
          name: values.vendor_name,
          vendor_type: values.vendor_type,
          moi_method_key: moiMethodsById[values.moi_method_id]?.key,
          exposure_ids: values?.exposure_ids?.includes(GENERAL_EXPOSURE_FAKE_ID) ? [] : values?.exposure_ids,
          method_specific: values.method_specific,
        }
      );

      setVendors((data?.vendors || []).map((vendor: MoiVendor) => ({ ...vendor, id: vendor.appraiser_code })));
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const errorMessage = error.response.data.message;
      if (errorMessage.startsWith('Notify User:')) {
        const notifyUserMessage = errorMessage.replace('Notify User:', '');
        setAlertMessage({
          message: notifyUserMessage,
          type: 'error',
        });
      } else {
        setAlertMessage({
          type: 'error',
          message: (error as AxiosError).message,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Text weight={Text.WEIGHTS.SEMI_BOLD}>{search_title}</Text>
      <div className="mb- mb-40 mt-20 flex gap-32">
        <TextFieldFormik id="vendor_name" label="Name" fullWidth disabled={isSubmitting} />
        <TextFieldFormik id="vendor_distance" label="Distance" fullWidth disabled={isSubmitting} select>
          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/*@ts-ignore*/}
          {MOI_VENDOR_SEARCH_DISTANCES.map((distance) => (
            <MenuItem key={distance} value={distance}>
              {distance}
            </MenuItem>
          ))}
        </TextFieldFormik>
        <TextFieldFormik id="vendor_type" label="Type" fullWidth disabled={isSubmitting} select>
          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/*@ts-ignore*/}
          {Object.keys(MOI_VENDOR_TYPES).map((type_key: AppraiserTypes) => (
            <MenuItem key={type_key} value={type_key}>
              {MOI_VENDOR_TYPES[type_key]}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </div>
      <div className="flex">
        <FsButton
          onClick={() => handleSearchClick()}
          color="primary"
          variant="contained"
          disabled={isLoading || isSubmitting}
        >
          Search
        </FsButton>
      </div>
      <div className="mt-32 max-h-[560px]">
        <LoadingSwitch isLoading={isLoading} isError={false}>
          {vendors.length > 0 && (
            <SortableTable
              rows={vendors}
              columns={columns}
              defaultOrderColumn={columns.findIndex((column) => column.id === 'distance')}
            />
          )}
        </LoadingSwitch>
      </div>
    </div>
  );
};

export default SearchVendorStep;
