/* eslint-disable react/prop-types */
import React, { Component, useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import * as PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { connect, getIn, useFormikContext } from 'formik';
import { isNil, noop } from 'lodash';
import moment from 'moment';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import DatePickerField from '~/components/core/Molecules/Fields/DatePickerField/DatePickerField';
import MultiSelectField from '~/components/core/Molecules/Fields/MultiSelectField';
import TextField from '~/components/core/Molecules/Fields/TextField';

import { isoDateToUs, localTimeToViewTime } from '../DateTimeUtils';

import { useRestrictedPermissions } from './core/Permissions/RestrictedPermissions';
import { useCurrencyFormatter } from './CurrencyFormatterContext';
import { PencilIcon } from './icons';
import InlineIconButton from './InlineIconButton';

import styles, { useStyles } from '../assets/styles';

const ShowOnlyTextField = ({
  classes,
  label,
  disabled,
  onEdit,
  showOnlyValueComponent,
  maxHeight,
  width,
  doNotRenderInTypography,
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { userHasContextPermissions, permissionDeniedTooltipText } = useRestrictedPermissions();

  let valueComponent;
  if (doNotRenderInTypography) {
    valueComponent = showOnlyValueComponent;
  } else {
    valueComponent = (
      <Typography display="block" variant="body1">
        {showOnlyValueComponent}
      </Typography>
    ); // for CSS consistency we wrap in Typography
  }

  const isEditable = onEdit && onEdit !== noop && !disabled;
  valueComponent = (
    <div style={{ display: 'inline-flex', alignItems: 'center', width }}>
      <span
        style={{
          maxHeight,
          width,
          overflow: 'auto',
          height: '100%',
          whiteSpace: 'pre-wrap',
        }}
      >
        {valueComponent}
      </span>
      {isEditable && (
        <span style={{ visibility: isMouseOver ? 'visible' : 'hidden' }}>
          <InlineIconButton
            useIconButton
            size="small"
            className={classes.inlineEditIcon}
            icon={PencilIcon}
            onClick={(e) => onEdit(e)}
            tooltipTitle={userHasContextPermissions ? null : permissionDeniedTooltipText}
            disabled={!userHasContextPermissions}
          />
        </span>
      )}
    </div>
  );

  return (
    <div
      className={classes.showField}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <Typography display="block" variant="caption">
        {label}
      </Typography>
      {valueComponent}
    </div>
  );
};

ShowOnlyTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  showOnlyValueComponent: PropTypes.node,
  disabled: PropTypes.bool,
  onEdit: PropTypes.func,
  maxHeight: PropTypes.string,
  width: PropTypes.string,
  doNotRenderInTypography: PropTypes.bool,
};

const TextFieldFormik = ({
  id,
  disabled = false,
  children = null,
  showOnly = false,
  onEdit = noop,
  multiline = false,
  rows = '',
  fullWidth = false,
  clearable = false,
  onClear = noop,
  helperText = '',
  onChange = undefined,
  ...rest
}) => {
  const classes = useStyles();

  const { setFieldTouched, values, touched, errors, handleBlur, setFieldValue, handleChange } = useFormikContext();

  if (showOnly) {
    const { label, value, select, SelectProps } = rest;

    // verify that we have any value to show (and avoid cryptic errors on console)
    if (value === undefined && id === undefined) {
      throw new Error(`TextFieldFormik was requested to render "${label}" but no id or value were give.`);
    }

    // if someone supplied a value, use it, otherwise, lookup the values array
    let currValue = value !== undefined ? value : values && getIn(values, id) !== undefined ? getIn(values, id) : '';

    if (select) {
      React.Children.forEach(children, (menuItem) => {
        if (menuItem.props.value === currValue) {
          currValue = menuItem.props.children;
        }
      });
    }

    if (SelectProps && SelectProps.multiple && SelectProps.renderValue) {
      currValue = SelectProps.renderValue(currValue);
    }

    const maxHeight = multiline && rows ? `${1.7 * rows}em` : undefined;

    return (
      <ShowOnlyTextField
        classes={classes}
        onEdit={onEdit}
        showOnlyValueComponent={currValue}
        label={label}
        disabled={disabled}
        maxHeight={maxHeight}
        width={fullWidth ? '100%' : undefined}
        doNotRenderInTypography={select || (SelectProps && !!SelectProps.renderValue)}
      />
    );
  }

  const clearSelection = () => {
    setFieldValue(id, '');
    if (typeof onClear === 'function') {
      onClear();
    } else if (onClear) {
      throw new Error('onClear is defined but is not a function');
    }
  };
  return (
    <TextField
      disabled={disabled}
      value={getIn(values, id)}
      onChange={(value, event) => (onChange ? onChange(event) : handleChange(event))}
      onBlur={rest.select ? () => setFieldTouched(id, true) : handleBlur} // select doesn't have "id" or "name" in its onBlur
      error={getIn(errors, id) && getIn(touched, id)}
      helperText={(getIn(errors, id) && getIn(touched, id) && getIn(errors, id)) || helperText}
      id={id}
      multiline={multiline}
      rows={rows}
      fullWidth={fullWidth}
      // InputProps={inputPropsStyle}
      InputProps={{
        id,
        ...(rest.select && clearable
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{
                      padding: 4,
                      visibility: getIn(values, id) && !disabled ? 'visible' : 'hidden',
                      marginRight: 15,
                    }}
                    title="Clear"
                    onClick={() => clearSelection()}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }
          : {}),
      }}
      {...rest}
    >
      {children}
    </TextField>
  );
};

TextFieldFormik.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  showOnly: PropTypes.bool,
  onEdit: PropTypes.func,
  multiline: PropTypes.bool,
  rows: PropTypes.string,
  fullWidth: PropTypes.bool,
  clearable: PropTypes.bool,
  onClear: PropTypes.func,
  helperText: PropTypes.string,
  children: PropTypes.any,
  onChange: PropTypes.func,
};

class MultiSelectTextFieldFormikInner extends Component {
  render() {
    const {
      disabled,
      renderValue,
      options,
      classes,
      showOnly,
      onEdit,
      formik,
      renderOption,
      sortAlphabetic,
      withOptionChips,
      disabledOptions,
      addAllOption,
      allOptionValue,
      ...rest
    } = this.props;

    const { id } = rest;

    if (showOnly) {
      const { value, label, disabled } = rest;

      let currValue =
        value !== undefined
          ? renderValue(value)
          : formik && getIn(formik.values, id) !== undefined
          ? renderValue(getIn(formik.values, id))
          : '';

      return (
        <ShowOnlyTextField
          classes={classes}
          onEdit={onEdit}
          showOnlyValueComponent={currValue}
          label={label}
          disabled={disabled}
        />
      );
    }

    const { values, touched, errors, handleBlur, setFieldTouched, setFieldValue } = formik;

    const curValue = getIn(values, id) ?? [];

    return (
      <div>
        <MultiSelectField
          value={curValue}
          onChange={(newValues) => setFieldValue(id, newValues)}
          onBlur={rest.select ? () => setFieldTouched(id, true) : handleBlur} // select doesn't have "id" or "name" in its onBlur
          error={getIn(errors, id) && !!getIn(touched, id)} // if values[id] is an array so getIn(touched, id) also would be array and not true/false
          helperText={getIn(errors, id) && getIn(touched, id) && getIn(errors, id)}
          renderValue={renderValue}
          disabled={disabled}
          name={id}
          renderOption={renderOption}
          options={options}
          sortAlphabetic={sortAlphabetic}
          withOptionChips={withOptionChips}
          disabledOptions={disabledOptions}
          addAllOption={addAllOption}
          allOptionValue={allOptionValue}
          {...rest}
        />
      </div>
    );
  }
}

MultiSelectTextFieldFormikInner.propTypes = {
  classes: PropTypes.object.isRequired,
  showOnly: PropTypes.bool,
  onEdit: PropTypes.func,
  renderValue: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  emptyChoiceValue: PropTypes.string,
  renderOption: PropTypes.func,
  formik: PropTypes.object,
  withOptionChips: PropTypes.bool,
  disabledOptions: PropTypes.array,
  addAllOption: PropTypes.bool,
  allOptionValue: PropTypes.string,
};

const MultiSelectTextFieldFormik = connect(MultiSelectTextFieldFormikInner);

const MultiSelectIdLabelTextFieldFormik = withStyles(styles)(
  connect((props) => {
    const { renderValue, options, classes, showOnly, emptyChoiceValue, onEdit, formik, ...rest } = props;

    const { id } = rest;
    const renderValueFromSelectedIds = (selectedIds) =>
      renderValue(options.filter((option) => selectedIds.includes(option.id)));

    if (showOnly) {
      const { value, label, disabled } = rest;

      let currValue =
        value !== undefined
          ? renderValueFromSelectedIds(value)
          : formik && getIn(formik.values, id) !== undefined
          ? renderValueFromSelectedIds(getIn(formik.values, id))
          : '';

      return (
        <ShowOnlyTextField
          classes={classes}
          onEdit={onEdit}
          showOnlyValueComponent={currValue}
          label={label}
          disabled={disabled}
          doNotRenderInTypography
        />
      );
    }

    const { values, touched, errors, handleBlur, handleChange, setFieldTouched } = formik;

    const currValue = getIn(values, id);

    const isChecked = (option) => getIn(values, id)?.indexOf(option?.id ?? option) > -1;

    const getCheckboxStyle = (option) => ({ color: isChecked(option) ? '#1A9C9E' : 'unset' });

    return (
      <TextField
        value={currValue || []}
        onChange={(value, e) => {
          handleChange(e);
        }}
        onBlur={rest.select ? () => setFieldTouched(id, true) : handleBlur} // select doesn't have "id" or "name" in its onBlur
        error={getIn(errors, id) && getIn(touched, id)}
        helperText={getIn(errors, id) && getIn(touched, id) && getIn(errors, id)}
        select
        SelectProps={{
          multiple: true,
          renderValue: renderValueFromSelectedIds,
        }}
        name={id}
        size="small"
        {...rest}
      >
        {emptyChoiceValue && (
          <MenuItem key={emptyChoiceValue} value={emptyChoiceValue}>
            <Checkbox
              checked={getIn(values, id).indexOf(emptyChoiceValue) > -1}
              style={getCheckboxStyle(emptyChoiceValue)}
            />
            <ListItemText primary={emptyChoiceValue} />
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox checked={isChecked(option)} style={getCheckboxStyle(option)} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </TextField>
    );
  })
);

MultiSelectIdLabelTextFieldFormik.propTypes = {
  renderValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.any, label: PropTypes.any })).isRequired,
};

class DatePickerTextFieldFormikInner extends Component {
  static contextTypes = {
    formik: PropTypes.object,
  };

  state = {
    datePickerError: '',
  };

  render() {
    const { classes, showOnly, onEdit, formik, placeholder, format, containerClassName, nullIfEmpty, ...rest } =
      this.props;
    const { datePickerError } = this.state;

    const { id } = rest;

    if (showOnly) {
      const { label, value, disabled } = this.props;

      // if someone supplied a value, use it, otherwise, lookup the values array
      let currValue =
        value !== undefined ? value : formik && getIn(formik.values, id) !== undefined ? getIn(formik.values, id) : '';
      if (currValue) {
        currValue = isoDateToUs(currValue);
      }

      return (
        <ShowOnlyTextField
          classes={classes}
          onEdit={onEdit}
          showOnlyValueComponent={currValue}
          label={label}
          disabled={disabled}
        />
      );
    }

    const { values, touched, errors, setFieldTouched, setFieldValue } = formik;

    const getEmptyValue = () => (nullIfEmpty ? null : '');

    return (
      <div className={containerClassName}>
        <DatePickerField
          format={format}
          value={getIn(values, id)} // new Date() assumes UTC, moment assumes local time, values contains local time
          placeholder={placeholder}
          onChange={(date) => setFieldValue(id, date ? date.format('YYYY-MM-DD') : getEmptyValue())} // date is a moment object
          // onClose={() => setFieldTouched(id, true)} // calendar was opened and closed -- don't use! currently will trigger Formik verification but if we just chose a date, it might not have updated and verification will be on older value (and will remain)
          onBlur={() => setFieldTouched(id, true)}
          error={getIn(errors, id) && getIn(touched, id)}
          onError={(error) => datePickerError !== error && this.setState({ datePickerError: error })}
          helperText={getIn(errors, id) && getIn(touched, id) && (datePickerError || getIn(errors, id))}
          {...rest}
        />
      </div>
    );
  }
}

const DatePickerTextFieldFormik = connect(DatePickerTextFieldFormikInner);

class TimePickerTextFieldFormikInner extends Component {
  static contextTypes = {
    formik: PropTypes.object,
  };

  state = {
    timePickerError: '',
  };

  render() {
    const { classes, showOnly, onEdit, formik, setTouchedOnChange = false, ...rest } = this.props;

    const { id } = rest;

    const { timePickerError } = this.state;
    if (showOnly) {
      const { label, value, disabled } = this.props;

      // if someone supplied a value, use it, otherwise, lookup the values array
      let currValue =
        value !== undefined ? value : formik && getIn(formik.values, id) !== undefined ? getIn(formik.values, id) : '';

      // Presenting the date with the right format, if not empty
      if (currValue) currValue = localTimeToViewTime(currValue);

      return (
        <ShowOnlyTextField
          classes={classes}
          onEdit={onEdit}
          showOnlyValueComponent={currValue}
          label={label}
          disabled={disabled}
        />
      );
    }

    const { values, touched, errors, setFieldTouched, setFieldValue } = formik;

    let timeFormat = moment.localeData().longDateFormat('LT');
    if (timeFormat.startsWith('h:')) {
      // There is a bug, in case the format is 'h:mm A' it's not possible to enter two digit hour (e.g 11:32 PM), so change to 'hh:mm A'
      // This should be solved in KeyboardTimePicker version 4 - https://github.com/mui-org/material-ui-pickers/issues/1286
      timeFormat = 'h' + timeFormat;
    }
    return (
      <div>
        <DatePickerField
          isOnlyTimeSelection
          format={timeFormat}
          value={getIn(values, id)}
          onChange={(date) => {
            if (setTouchedOnChange) {
              setFieldTouched(id, true);
            }
            setFieldValue(id, date ? date.format('HH:mm') : '');
          }} // date is a moment object
          // onClose={() => setFieldTouched(id, true)} // clock was opened and closed -- don't use! currently will trigger Formik verification but if we just chose a date, it might not have updated and verification will be on older value (and will remain)
          onBlur={() => setFieldTouched(id, true)}
          onError={(error) => timePickerError !== error && this.setState({ timePickerError: error })}
          helperText={getIn(errors, id) && getIn(touched, id) && (timePickerError || getIn(errors, id))}
          error={!!(getIn(errors, id) && getIn(touched, id) && (timePickerError || getIn(errors, id)))}
          {...rest}
        />
      </div>
    );
  }
}

const TimePickerTextFieldFormik = connect(TimePickerTextFieldFormikInner);

function YearPickerSelectTextFieldFormikInner(props) {
  const { formik, id, label, showOnly, ...rest } = props;
  const { values, setFieldValue, setFieldTouched } = formik;

  return (
    <NumberFormat
      customInput={TextFieldFormik}
      allowNegative={false}
      id={id}
      label={label}
      format="####"
      mask="_"
      value={getIn(values, id)}
      onValueChange={(numberValues) => {
        const { floatValue } = numberValues;
        setFieldValue(id, floatValue === undefined ? '' : floatValue);
      }}
      onBlur={() => setFieldTouched(id, true)}
      showOnly={showOnly}
      {...rest}
    />
  );
}

YearPickerSelectTextFieldFormikInner.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

const YearPickerSelectTextFieldFormik = connect(YearPickerSelectTextFieldFormikInner);

function MonetaryValueTextFieldFormik(props) {
  const { currencyFormatter, symbol, groupSeparator, decimalSeparator } = useCurrencyFormatter();

  return (
    <NumericTextFieldFormik
      thousandSeparator={groupSeparator ? groupSeparator : true}
      decimalSeparator={decimalSeparator || '*'} // decimal separator must have 1 character value
      decimalScale={!decimalSeparator ? 0 : 2} // if currency does not have decimalSeparator set decimalScale to 0
      fixedDecimalScale
      allowNegative={false}
      prefix={symbol}
      placeholder={currencyFormatter.format(0)}
      {...props}
    />
  );
}

function NumericTextFieldFormik(props) {
  const { id, label, onChange, ...rest } = props;
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { groupSeparator, decimalSeparator } = useCurrencyFormatter();

  return (
    <NumberFormat
      customInput={TextFieldFormik}
      thousandSeparator={groupSeparator ? groupSeparator : true}
      decimalSeparator={decimalSeparator || '*'} // decimal separator must have 1 character value
      decimalScale={!decimalSeparator ? 0 : 2} // if currency does not have decimalSeparator set decimalScale to 0
      id={id}
      label={label}
      value={getIn(values, id)}
      onValueChange={(numberValues) => {
        const { floatValue } = numberValues;
        setFieldValue(id, floatValue === undefined ? null : floatValue);
        if (onChange) onChange(floatValue);
      }}
      onBlur={() => setFieldTouched(id, true)}
      {...rest}
    />
  );
}

NumericTextFieldFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

const DatePickerTextFieldFormikWithStyles = withStyles(styles)(DatePickerTextFieldFormik);
const TimePickerTextFieldFormikWithStyles = withStyles(styles)(TimePickerTextFieldFormik);
const MultiSelectTextFieldFormikWithStyles = withStyles(styles)(MultiSelectTextFieldFormik);

export {
  DatePickerTextFieldFormikWithStyles as DatePickerTextFieldFormik,
  MonetaryValueTextFieldFormik,
  MultiSelectIdLabelTextFieldFormik,
  MultiSelectTextFieldFormikWithStyles as MultiSelectTextFieldFormik,
  NumericTextFieldFormik,
  ShowOnlyTextField,
  TextFieldFormik,
  TimePickerTextFieldFormikWithStyles as TimePickerTextFieldFormik,
  YearPickerSelectTextFieldFormik,
};

export default TextFieldFormik;

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

export function useAllOptionOnMultiSelect(listValue, listId, allOption) {
  const { setFieldValue } = useFormikContext();
  const prevRef = usePrevious(listValue);

  useEffect(() => {
    if (!isNil(allOption) && listValue.length > 1 && listValue.includes(allOption)) {
      if (!prevRef.includes(allOption)) {
        setFieldValue(listId, [allOption]);
      } else {
        setFieldValue(
          listId,
          listValue.filter((option) => option !== allOption)
        );
      }
    }
  }, [listValue, listId, setFieldValue, prevRef, allOption]);
}

export function useSetDefaultFieldsOnChange(
  value,
  fieldsWithDefaultValues,
  onEveryChange,
  disabled,
  doNotIgnoreEmptyPrev
) {
  const { setFieldValue } = useFormikContext();
  const prevRef = usePrevious(value);

  useEffect(() => {
    if (prevRef !== value) {
      // to prevent clearing the initial values
      if ((prevRef || doNotIgnoreEmptyPrev) && !disabled) {
        Object.keys(fieldsWithDefaultValues).forEach((id) => {
          setFieldValue(id, fieldsWithDefaultValues[id]);
        });
      }
      !disabled && onEveryChange && onEveryChange();
    }
  }, [value, setFieldValue, prevRef, fieldsWithDefaultValues, onEveryChange, disabled, doNotIgnoreEmptyPrev]);
}
