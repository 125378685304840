import React from 'react';

import { ActionPanel } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionPanel';
import EmptyStateIcon from '~/components/AiChat/SideBarDialog/icons/EmptyStateIcon';
import type { AiAction } from '~/components/AiChat/types';
import EmptyState from '~/components/core/EmptyState';
import LoadingIndicator from '~/components/LoadingIndicator';

interface ActionsProps {
  actions: AiAction[];
  isLoading: boolean;
  reloadActions: () => Promise<void>;
}

const sortActionsById = (a: AiAction, b: AiAction) => ((a.action_id || '') > (b.action_id || '') ? 1 : -1);

export const Actions: React.FC<ActionsProps> = ({ actions, isLoading, reloadActions }) => {
  const filteredActions = React.useMemo(
    () => actions?.filter((action: AiAction) => !!action.task_data.explanation).sort(sortActionsById),
    [actions]
  );

  return (
    <div className="__ACTIONS_ROOT__ mt-16 flex h-[calc(100vh-230px)] flex-col gap-20 overflow-scroll">
      <div className="__ACTIONS_HEADER__ h-min-[100px] gap-100 flex flex-col">
        <h2 className="mx-20" hidden={!filteredActions?.length && !isLoading}>
          Claim Actions
        </h2>
        {!filteredActions?.length && !isLoading && (
          <div className="flex h-[calc(100vh-650px)] w-full flex-col items-center justify-center px-[100px]">
            <EmptyState
              className="text-center"
              title="No recommended actions yet"
              illustration={<EmptyStateIcon height={200} width={200} />}
            />
          </div>
        )}
        {filteredActions?.length > 0 && (
          <ul className="my-0 ml-20 flex flex-col gap-20 p-0">
            {filteredActions?.map((action: AiAction) => (
              <ActionPanel action={action} key={action.action_id} onActionUpdate={reloadActions} />
            ))}
          </ul>
        )}
        {isLoading ||
          (!actions?.length && (
            <div className="flex h-[calc(100vh-600px)] items-center justify-center">
              <LoadingIndicator />
            </div>
          ))}
      </div>
    </div>
  );
};
