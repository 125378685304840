import React from 'react';
import { Remarkable } from 'remarkable';

import { RoundedPanel } from '~/components/AiChat/SideBarDialog/components/RoundedPanel/RoundedPanel';
import { replaceDatesWithLocaleString } from '~/components/AiChat/SideBarDialog/utils';
import type { AiAction, ReserveUpdateTaskData } from '~/components/AiChat/types';
import { AI_ACTION_TYPES } from '~/components/AiChat/types';
import { reportErrorInProductionOrThrow } from '~/Utils';

import '../../MarkdownTemplate.css';

interface ActionsPanelProps {
  action: AiAction;
}

export const ReserveUpdateActionInner: React.FC<ActionsPanelProps> = ({ action }) => {
  if (!action?.task_data || action?.task_data?.task_type !== AI_ACTION_TYPES.RESERVE_UPDATE) {
    reportErrorInProductionOrThrow(new Error('Invalid action task data for CoverageDecisionAction'));
  }

  const taskData = action.task_data as ReserveUpdateTaskData;

  const md = new Remarkable({ breaks: true });
  const parsedText = md.render(replaceDatesWithLocaleString(taskData.task_text));

  return (
    <RoundedPanel variant="clear">
      {taskData.task_text ? (
        <div
          className="task-markdown additional-actions flex flex-col px-12 text-sm"
          dangerouslySetInnerHTML={{ __html: parsedText }}
        />
      ) : null}
    </RoundedPanel>
  );
};
