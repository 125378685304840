import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Autocomplete from '~/components/core/Molecules/Fields/AutoComplete';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import { COMMUNICATION_CHANNEL_DICT } from '~/Types';
import { formatSortOrder } from '~/Utils';

import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import ContactSearchContainer from '../ContactSearch/ContactSearchContainer';
import ExposuresLabelFilter from '../exposures/ExposuresLabelsFilter';
import { getUserRelatedExposuresId } from '../exposures/ExposureUtils';
import { useCms } from '../hooks/useCms';
import useOrganization from '../OrganizationContext';
import useDataFetcher from '../useDataFetcher';

import CommunicationsTable from './CommunicationsTable';
import { getAllSearchableContactRoles } from './ContactUtils';
import ViewCommunicationCardContainer from './ViewCommunicationCardContainer';

const CommunicationsTab = ({ isTabActive = true }) => {
  const { claim, onClaimUpdate } = useClaim();
  const { user } = useCms();
  const communicationsPerPage = 10;

  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  const [selectedContact, setSelectedContact] = useState('');
  const [selectedAdjuster, setSelectedAdjuster] = useState('');
  const [selectedCommunicationId, setSelectedCommunicationId] = useState(undefined);
  const [filteredExposuresIds, setFilteredExposuresIds] = useState(() => getUserRelatedExposuresId(user, claim));
  const [page, setPage] = useState(0);
  const [sortByColumn, setSortByColumn] = useState({ id: 'datetime', order: 'desc' });
  const [selectedCommunicationChannel, setSelectedCommunicationChannel] = useState('');

  const {
    isLoading,
    isError,
    data: {
      communications,
      count: totalCommunicationsCount,
      communications_adjusters: communicationAdjusters,
      available_channels,
    } = {},
    reloadData,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/communications`, {
    params: {
      page_number: page + 1,
      results_per_page: communicationsPerPage,
      sort_by_column: formatSortOrder(sortByColumn),
      exposures_filters: filteredExposuresIds,
      channel_type_filter: undefined,
      contact_id: selectedContact ? selectedContact.id : undefined,
      user_id: selectedAdjuster ? selectedAdjuster.id : undefined,
      communication_channel: selectedCommunicationChannel ? selectedCommunicationChannel : undefined,
    },
  });

  useEffect(() => {
    reloadData && reloadData();
  }, [claim, reloadData]);

  const handleSelectCommunication = (communicationId) => {
    setSelectedCommunicationId(communicationId);
  };

  const maxHeightPx = `${window.innerHeight - 248}px`; // Screen size minus the mainBar+claimBar+tabs+paddings TODO - fix to be logical

  const displayLoading = isLoading && !communications;

  const resetPage = () => setPage(0);

  return isTabActive ? (
    <>
      <Grid container>
        <Grid item xs={6}>
          <div className={classes.cardDivRow}>
            <div className="flex items-center">
              <ContactSearchContainer
                disallowNew
                fixedSearchResults
                onSelectContact={(contact) => {
                  setSelectedContact(contact);
                  resetPage();
                }}
                selectedContactId={selectedContact ? selectedContact.id : undefined}
                selectedContactDisplayName={selectedContact ? selectedContact.full_name : undefined}
                TextFieldProps={{
                  size: 'small',
                  label: 'Filter by Contact',
                }}
                acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict).concat(['user'])}
                disallowOutOfClaimContacts
                disabled={isLoading}
                class
              />
              <div className="ml-12">
                <Autocomplete
                  id="adjuster"
                  label="Filter by Adjuster"
                  size="small"
                  options={_.orderBy(communicationAdjusters, ['username'])}
                  getOptionLabel={(option) => option.username}
                  onChange={(_, newVal) => {
                    setSelectedAdjuster(newVal || '');
                    resetPage();
                  }}
                  value={selectedAdjuster || null}
                  className="w-[200px]"
                />
              </div>
              <div className="ml-12">
                <Autocomplete
                  id="channel"
                  label="Filter by Type"
                  size="small"
                  options={available_channels}
                  getOptionLabel={(option) => COMMUNICATION_CHANNEL_DICT[option]}
                  onChange={(_, newVal) => {
                    resetPage();
                    setSelectedCommunicationChannel(newVal || '');
                  }}
                  value={selectedCommunicationChannel || null}
                  className="w-[200px]"
                />
              </div>
              <Button
                style={{ marginLeft: '12px' }}
                disabled={!selectedContact && !selectedAdjuster && !selectedCommunicationChannel}
                onClick={() => {
                  setSelectedContact('');
                  setSelectedAdjuster('');
                  setSelectedCommunicationChannel('');
                  resetPage();
                }}
              >
                Clear
              </Button>
            </div>
          </div>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6}>
          <ExposuresLabelFilter filterList={filteredExposuresIds} onUpdateFiltered={setFilteredExposuresIds} />
        </Grid>
        <Grid item md={selectedCommunicationId ? 7 : 12}>
          <CardDialog noCardTitle>
            {displayLoading && <SkeletonTable rowsCount={15} columnsCount={5} isError={isError} />}
            {!displayLoading && (
              <CommunicationsTable
                communications={communications}
                selectedCommunicationId={selectedCommunicationId}
                onSelectCommunication={handleSelectCommunication}
                maxHeight={`${window.innerHeight - 300}px`} // ~280 all the stuff upper to the table
                paginationProps={{
                  page,
                  rowsPerPage: communicationsPerPage,
                  onChangePage: (_, newPage) => {
                    setPage(newPage);
                  },
                  disabled: isLoading,
                  count: totalCommunicationsCount || 0,
                  rowsPerPageOptions: [communicationsPerPage],
                }}
                onSortByColumn={(_, sortByColumn) => {
                  setSortByColumn(sortByColumn);
                  resetPage();
                }}
                sortByColumn={sortByColumn}
                removeEmailDomainColumn
              />
            )}
          </CardDialog>
        </Grid>
        {selectedCommunicationId && (
          <Grid item md={5}>
            <CardDialog
              headerStyle={{ padding: '0' }}
              contentStyle={{ padding: '8px 0 0 0' }}
              containerStyle={{ marginLeft: '16px', maxHeight: maxHeightPx, overflow: 'auto' }}
            >
              <ViewCommunicationCardContainer
                key={selectedCommunicationId}
                communicationId={selectedCommunicationId}
                onUpdate={onClaimUpdate}
              />
            </CardDialog>
          </Grid>
        )}
      </Grid>
    </>
  ) : null;
};

CommunicationsTab.propTypes = {
  isTabActive: PropTypes.bool,
};

export default CommunicationsTab;
