import type { CSSProperties, ReactElement } from 'react';
import React from 'react';
import type { InputLabelProps, InputProps, SelectProps } from '@material-ui/core';
import { TextField as MuiTextField } from '@material-ui/core';
import type { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';

export interface TextFieldProps<T> {
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: React.ReactNode;
  id?: string;
  InputLabelProps?: Partial<InputLabelProps>;
  multiline?: boolean;
  rows?: string | number;
  value?: T;
  onChange?: (newValue: T, event: React.ChangeEvent) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label?: string;
  className?: string;
  autoFocus?: boolean;
  ref?: React.Ref<HTMLDivElement>;
  inputRef?: React.Ref<HTMLDivElement>;
  style?: CSSProperties;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  placeholder?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  inputProps?: InputBaseComponentProps;
  name?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  rowsMax?: number;

  // select only props
  select?: boolean; // should be used from the select component only
  SelectProps?: Partial<SelectProps>; // should be used from the select component only
  children?: React.ReactNode; // should be used from the select component only
  InputProps?: Partial<InputProps>; // should be used from the select component only
}

const TextField = <T,>({
  value,
  disabled,
  multiline,
  rows,
  fullWidth,
  helperText,
  onChange,
  onBlur,
  error,
  id,
  variant,
  ...rest
}: TextFieldProps<T>): ReactElement => {
  const calcId = rest.select && !rest.SelectProps?.multiple ? undefined : id;
  return (
    <MuiTextField
      disabled={disabled}
      value={value}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={(e) => (onChange ? onChange(e.target.value, e) : null)}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      id={calcId}
      name={id}
      multiline={multiline}
      rows={rows}
      fullWidth={fullWidth}
      size="small"
      InputLabelProps={{ htmlFor: calcId }}
      variant={variant || 'standard'}
      {...rest}
    />
  );
};

export default TextField;
