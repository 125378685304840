import React from 'react';
import axios from 'axios';

import { CoverageDecisionActionInner } from '~/components/AiChat/SideBarDialog/Actions/Action/CoverageDecisionActionInner';
import { ReserveUpdateActionInner } from '~/components/AiChat/SideBarDialog/Actions/Action/ReserveUpdateActionInner';
import { INSIGHTS_ICONS } from '~/components/AiChat/SideBarDialog/utils';
import type { AiAction, AiChatExchangesAndReferencesApiResponse } from '~/components/AiChat/types';
import { AI_ACTION_TYPES } from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';
import { MainCard } from '~/components/core';
import Button from '~/components/core/Atomic/Buttons/Button';
import { CheckIcon } from '~/components/icons';
import { reportAxiosError, reportErrorInProductionOrThrow } from '~/Utils';
import cn from '~/Utils/cn';

import '../../MarkdownTemplate.css';

interface ActionsPanelProps {
  action: AiAction;
  onActionUpdate: () => Promise<void>;
}

const getInnerActionComponent = (action: AiAction) => {
  if (action.task_data?.task_type === AI_ACTION_TYPES.COVERAGE_DECISION) {
    return <CoverageDecisionActionInner action={action} />;
  } else if (action.task_data?.task_type === AI_ACTION_TYPES.RESERVE_UPDATE) {
    return <ReserveUpdateActionInner action={action} />;
  } else {
    reportErrorInProductionOrThrow(new Error(`Invalid action task data type ${action.task_data?.task_type}`));
  }
};

const getActionTitle = (action: AiAction) => {
  if (action.task_data?.task_type === AI_ACTION_TYPES.COVERAGE_DECISION) {
    return 'Decide on Coverage';
  } else if (action.task_data?.task_type === AI_ACTION_TYPES.RESERVE_UPDATE) {
    return 'Update Reserve';
  } else {
    reportErrorInProductionOrThrow(new Error(`Invalid action task data type ${action.task_data?.task_type}`));
  }
};

export const ActionPanel: React.FC<ActionsPanelProps> = ({ action, onActionUpdate }) => {
  const { claim, onAsyncClaimUpdate } = useClaim();

  const related_exposure = React.useMemo(
    () =>
      claim?.exposures?.find(
        (exposure: { id: string | undefined }) => exposure?.id?.toString() === action?.related_exposure_id
      ),
    [action, claim]
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [isDone, setIsDone] = React.useState(action.is_executed);

  React.useEffect(() => {
    setIsDone(!!action?.is_executed);
  }, [action]);

  const executeAction = async () => {
    if (isDone || action.task_data?.task_type !== AI_ACTION_TYPES.COVERAGE_DECISION) return;

    try {
      setIsDone(true);
      await axios.post<AiChatExchangesAndReferencesApiResponse>(
        `/api/v1/claims/${claim.id}/actions/${action.action_id}/execute`
      );
    } catch (error) {
      await reportAxiosError(error);
      setIsDone(false);
      throw error;
    } finally {
      await onActionUpdate();
      await onAsyncClaimUpdate();
    }
  };

  return (
    <div
      className={cn('animate-fadeIn gap-10 relative flex w-full items-center bg-white', {
        ['bg-slate-100']: isOpen,
      })}
    >
      <MainCard
        key="contained"
        collapsible
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        title={
          <div className="flex flex-col gap-2">
            <span className="text-lg font-bold">{getActionTitle(action)}</span>
            <span className="text-sm italic text-slate-800">{related_exposure?.label_text}</span>
          </div>
        }
        type="contained"
        isOpen={isOpen}
        onCollapseClick={(cardState) => setIsOpen(cardState)}
        titleAction={
          <div
            className={cn(
              'ml-auto flex h-[36px] w-[36px] shrink-0 cursor-pointer items-center justify-center rounded-md ',
              {
                ['bg-pink-200/70']: !isDone,
                ['bg-green-100']: isDone,
              }
            )}
          >
            {INSIGHTS_ICONS[isDone ? 'checked' : 'warning']}
          </div>
        }
      >
        <div className="relative flex w-full flex-col gap-12">
          <div className="flex flex-col gap-12 ">
            {getInnerActionComponent(action)}
            <div className="flex">
              <Button
                variant="contained"
                classes={{
                  root: 'ml-auto bg-green-700 text-white',
                  label: 'leading-none',
                  disabled: '[&&]:bg-green-700/10 [&&]:text-green-700',
                }}
                onClick={executeAction}
                disabled={isDone}
              >
                <CheckIcon
                  height={16}
                  width={16}
                  className={cn('mr-12 rounded-full border-1 border-solid border-white fill-white px-2 pb-2', {
                    ['border-green-700 fill-green-700']: isDone,
                  })}
                />
                {isDone ? 'Done' : 'Execute'}
              </Button>
            </div>
          </div>
        </div>
      </MainCard>
    </div>
  );
};
