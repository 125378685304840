import type { ChangeEvent } from 'react';
import React from 'react';
import type { InputProps } from '@material-ui/core';
import type { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { default as MuiAutocomplete } from '@material-ui/lab/Autocomplete';
import type { AutocompleteInputChangeReason } from '@mui/material';
import { noop } from 'lodash';

import type { TextFieldProps } from '~/components/core/Molecules/Fields/TextField';
import TextField from '~/components/core/Molecules/Fields/TextField';

export interface AutoCompleteProps<T> {
  id: string;
  label?: string;
  options: T[];
  value: T;
  getOptionLabel: (option: T) => string;
  textFieldProps?: TextFieldProps<T>;
  showOnly?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  sortAlphabetic?: boolean;
  onChange?: (event: ChangeEvent<T>, value?: T | null, reason?: string) => void;
  autoSelect?: boolean;
  autoComplete?: boolean;
  autoHighlight?: boolean;
  onInputChange?: (event: ChangeEvent<T>, value: string, reason: AutocompleteInputChangeReason) => void;
  className?: string;
  InputProps?: Partial<InputProps>;
  clearOnBlur?: boolean;
  freeSolo?: boolean;
  getOptionSelected?: (option: T, value: T) => boolean;
  renderOption?: () => React.ReactElement;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

const AutoComplete = <T,>({
  className,
  id,
  options,
  getOptionLabel,
  onChange = noop,
  value,
  disabled,
  autoSelect,
  autoHighlight,
  autoComplete,
  onInputChange = noop,
  label,
  fullWidth,
  textFieldProps,
  InputProps,
  clearOnBlur,
  freeSolo,
  getOptionSelected,
  renderOption,
  renderInput,
}: AutoCompleteProps<T>): JSX.Element => {
  return (
    <MuiAutocomplete
      className={className}
      id={id}
      options={options}
      getOptionLabel={getOptionLabel}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={onChange}
      filterOptions={(_, value) =>
        options.filter((option) => getOptionLabel(option).toLowerCase().includes(value.inputValue.toLowerCase()))
      }
      value={value}
      disabled={disabled}
      autoSelect={autoSelect}
      autoHighlight={autoHighlight}
      autoComplete={autoComplete}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onInputChange={onInputChange}
      clearOnBlur={clearOnBlur}
      freeSolo={freeSolo}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
      renderInput={(params) =>
        renderInput ? (
          <>{renderInput(params)}</>
        ) : (
          <TextField
            className="my-4"
            label={label}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fullWidth={fullWidth}
            {...params}
            {...textFieldProps}
            InputProps={{
              ...InputProps,
              ...params.InputProps,
              margin: 'dense', // to match other TextField components
            }}
          />
        )
      }
    />
  );
};

export default AutoComplete;
