import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withStyles } from '@material-ui/core';
import axios from 'axios';

import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import AutoComplete from '~/components/core/Molecules/Fields/AutoComplete';
import MultiSelectField from '~/components/core/Molecules/Fields/MultiSelectField';
import SingleSelectField from '~/components/core/Molecules/Fields/SingleSelectField';
import SubOrganizationSelect from '~/components/TPA/SubOrganizations/SubOrganizationSelect';
import { isQoverUser, reportAxiosError } from '~/Utils';

import { CONSTANTS, COUNTRIES_DICT } from '../Types';

import { getAllOrganizationWideRoles } from './communications/ContactUtils';
import ContactSearchContainerOutOfClaim from './ContactSearch/ContactSearchContainerOutOfClaim';
import SkeletonTable from './core/Skeletons/SkeletonTable';
import { withCmsContext } from './hooks/useCms';
import ClaimsTable from './ClaimsTable';
import { withOrganization } from './OrganizationContext';

import styles from '../assets/styles';

export const ClaimScreenDefaultRowsPerPage = 15;

const INITIAL_SEARCH_FILTER = {
  status: 'all',
  fileOwnerIds: [],
  selectedContact: '',
  subOrganizationId: '',
  selectedCountry: '',
};

const DEFAULT_SORT_BY_COLUMN = { id: 'date_of_loss' };

class ClaimsScreenContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isError: false,
      claims: [],
      selectedClaimId: undefined,
      selectedClaim: undefined,
      possibleFileOwners: [],
      allClaimsCount: '-',
      foundClaimsCount: '-',
      page: 0,
      rowsPerPage: ClaimScreenDefaultRowsPerPage,
      sortByColumn: DEFAULT_SORT_BY_COLUMN,
      ...INITIAL_SEARCH_FILTER,
    };
  }

  componentDidMount() {
    this.props.setPageTitle('Claims', 'Claims — Five Sigma CMS');

    this.getPossibleAdjusters()
      .then(() => this.getClaimsCount())
      .then(() => this.searchClaims())
      .then(() => this.setState({ isLoading: false }));
  }

  getPossibleAdjusters = () => {
    return axios
      .get('/api/v1/users/possible_adjusters')
      .then((response) => {
        let possibleFileOwners = response.data;
        possibleFileOwners = [{ username: 'Unassigned FNOLs', id: null }, ...possibleFileOwners];
        this.setState({ possibleFileOwners });
      })
      .catch((error) => {
        reportAxiosError(error);
        this.setState({ isError: true });
      });
  };

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        page: newPage,
      },
      this.searchClaims
    );
  };

  searchClaims = () => {
    const {
      status,
      fileOwnerIds,
      selectedContact,
      page,
      rowsPerPage,
      sortByColumn,
      subOrganizationId,
      selectedCountry,
    } = this.state;

    const statusNoAll = status === 'all' ? '' : status;
    const params = {
      status: statusNoAll,
      handling_adjuster: fileOwnerIds,
      contact_id: selectedContact.id,
      sub_organization_id: subOrganizationId ? subOrganizationId : undefined,
      policy_country: selectedCountry === 'all' ? '' : selectedCountry,
      page: page + 1, // page in the flask-SQLAlchemy is 1-based
      rows_per_page: rowsPerPage,
      sort_by_column: sortByColumn && `${sortByColumn.order === 'asc' ? '+' : '-'}${sortByColumn.id}`, // +<colName> for ascending, -<colName> for descending
    };

    return axios
      .get('/api/v1/claims', { params })
      .then((response) => this.setState({ claims: response.data.claims, foundClaimsCount: response.data.count }))
      .catch((error) => {
        this.setState({ isError: true });
        reportAxiosError(error);
      });
  };

  handleSortByColumn = (e, sortByColumn) => {
    this.setState(
      {
        page: 0, // reset the page when sorting
        sortByColumn,
      },
      this.searchClaims
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        rowsPerPage: parseInt(event.target.value),
        page: 0,
      },
      this.searchClaims
    );
  };

  getClaimsCount = () => {
    return axios
      .get('/api/v1/claims/count')
      .then((response) => this.setState({ allClaimsCount: response.data }))
      .catch((error) => {
        this.setState({ isError: true });
        reportAxiosError(error);
      });
  };

  handleContactChange = (newContact) => {
    this.setState(
      {
        selectedContact: newContact,
        sortByColumn: DEFAULT_SORT_BY_COLUMN,
        page: 0,
      },
      this.searchClaims
    );
  };

  handleSubOrgChange = (value) => {
    this.setState(
      {
        subOrganizationId: value,
        sortByColumn: DEFAULT_SORT_BY_COLUMN,
        page: 0,
      },
      this.searchClaims
    );
  };

  handleInputChange = (name) => (value) => {
    this.setState(
      {
        [name]: value,
        sortByColumn: DEFAULT_SORT_BY_COLUMN,
        page: 0,
      },
      this.searchClaims
    );
  };

  handleCountryChange = (value) => {
    this.setState(
      {
        selectedCountry: value,
        sortByColumn: DEFAULT_SORT_BY_COLUMN,
        page: 0,
      },
      this.searchClaims
    );
  };

  handleMultiSelectChange = (name, emptyChoiceValue) => (value) => {
    const val = value.includes(emptyChoiceValue) ? [] : value;
    this.setState(
      {
        [name]: val,
        sortByColumn: DEFAULT_SORT_BY_COLUMN,
        page: 0,
      },
      this.searchClaims
    );
  };

  render() {
    const { classes, user, organizationContactRolesDict, subOrganizationEnabled, isMultipleCountriesEnabled } =
      this.props;
    const { isLoading, isError, claims, selectedContact } = this.state;

    const {
      fileOwnerIds,
      status,
      possibleFileOwners,
      allClaimsCount,
      foundClaimsCount,
      page,
      rowsPerPage,
      subOrganizationId,
      selectedCountry,
    } = this.state;

    const possibleFileOwnersById = possibleFileOwners.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

    return (
      <>
        <div className={classes.cardDivRow}>
          <Grid container spacing={1}>
            <Grid item>
              <MultiSelectField
                id="file_owners"
                label="File / Exposure owner"
                className={classes.textFieldRow}
                value={fileOwnerIds}
                onChange={this.handleMultiSelectChange('fileOwnerIds', 'All')}
                renderValue={(selectedIds) =>
                  selectedIds.map((selectedId) => possibleFileOwnersById[selectedId].username).join(', ')
                }
                renderOption={(fileOwnerId) => possibleFileOwnersById[fileOwnerId].username}
                addAllOption
                allOptionValue="All"
                options={possibleFileOwners.map((fileOwner) => fileOwner.id)}
              />
            </Grid>
            <Grid item>
              <SingleSelectField
                id="status"
                label="Status"
                className={classes.textFieldRow}
                value={status}
                onChange={this.handleInputChange('status')}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="open">Open</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
              </SingleSelectField>
            </Grid>

            {subOrganizationEnabled ? (
              <div>
                <SubOrganizationSelect
                  value={subOrganizationId}
                  onChange={(value) => this.handleSubOrgChange(value)}
                  className={classes.textFieldRow}
                />
              </div>
            ) : null}

            {isMultipleCountriesEnabled ? (
              <Grid item>
                <AutoComplete
                  id="country"
                  label="Policy Country"
                  className="-mt-4 ml-2 mr-2 w-[200px]"
                  value={selectedCountry}
                  onChange={(e, newVal) => this.handleCountryChange(newVal)}
                  options={['all', ...Object.keys(COUNTRIES_DICT)]}
                  getOptionLabel={(countryKey) => (countryKey === 'all' ? 'All' : COUNTRIES_DICT[countryKey])}
                />
              </Grid>
            ) : null}

            <Grid item>
              <ContactSearchContainerOutOfClaim
                disallowNew
                onSelectContact={this.handleContactChange}
                selectedContactId={selectedContact ? selectedContact.id : undefined}
                selectedContactDisplayName={selectedContact ? selectedContact.full_name : undefined}
                TextFieldProps={{
                  size: 'small',
                  label: 'Filter by Contact',
                  className: classes.textFieldRow,
                }}
                acceptedRoles={getAllOrganizationWideRoles(organizationContactRolesDict)}
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.cardDivRow}>
          <span
            className={classes.textFieldRow}
          >{`${foundClaimsCount} out of ${allClaimsCount} claims match the selected filters`}</span>
          <Link
            href=""
            onClick={(e) => {
              e.preventDefault();
              this.setState(
                {
                  ...INITIAL_SEARCH_FILTER,
                  page: 0,
                  sortByColumn: DEFAULT_SORT_BY_COLUMN,
                },
                this.searchClaims
              );
            }}
            className={classes.textFieldRow}
          >
            Reset filters
          </Link>
        </div>
        {isLoading ? (
          <SkeletonTable rowsCount={ClaimScreenDefaultRowsPerPage} columnsCount={9} isError={isError} />
        ) : (
          <ClaimsTable
            claims={claims}
            openInNewTab
            hideLastTouchedDate
            hideReportedDate={isQoverUser(user)}
            paginationProps={{
              page,
              rowsPerPage,
              onChangePage: this.handleChangePage,
              count: foundClaimsCount,
              onChangeRowsPerPage: this.handleChangeRowsPerPage,
              rowsPerPageOptions: [10, ClaimScreenDefaultRowsPerPage, CONSTANTS.DEFAULT_CLAIMS_PER_PAGE, 30],
            }}
            onSortByColumn={this.handleSortByColumn}
          />
        )}
      </>
    );
  }
}

ClaimsScreenContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  organizationContactRolesDict: PropTypes.object.isRequired,
  subOrganizations: PropTypes.array,
  subOrganizationEnabled: PropTypes.bool,
  userOrganization: PropTypes.object.isRequired, // from cms context
  isMultipleCountriesEnabled: PropTypes.bool, // from organization context
};

export default withCmsContext(withOrganization(withStyles(styles)(ClaimsScreenContainer)));
