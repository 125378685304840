import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import Downshift from 'downshift';
import { debounce } from 'lodash';

import { ContactEntity } from '~/components/Contact';
import { FsButton, FsMenuItem, PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '~/components/core';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import TextField from '~/components/core/Molecules/Fields/TextField';
import { ContactIcon } from '~/components/icons';

const DEBOUNCE_TIME = 500;

export class ContactSearch extends Component {
  constructor(props) {
    super(props);
    this.searchTextFieldEl = React.createRef();
    this.searchTextFieldInputEl = React.createRef();
    this.state = { inputCopy: '', isFocused: false, isHover: false }; // hack for onFocus of existing contact
  }

  render() {
    const {
      textInputRef,
      contactsList,
      contactSelected,
      isSearchInProgress,
      onSearchValueChange,
      onContactSelect,
      onContactUpdate,
      disallowNew,
      TextFieldProps,
      fixedSearchResults,
      initialSelectedContact,
      RemoveOutOfClaimDivider,
      organizationContactRolesDict,
      disableAdvanceSearch,
      handleAdvancedSearch,
      shouldShowAllOption,
      onShowAllContacts,
    } = this.props;

    const { inputCopy, isFocused, isHover } = this.state;

    const MAX_CONTACT_TO_DISPLAY = 5;
    const contactsListTruncated = contactsList.slice(0, MAX_CONTACT_TO_DISPLAY);
    const firstContactFetchedIdx = contactsListTruncated.findIndex((contact) => contact.is_fetched);

    let paperStyle = { zIndex: 100 };

    if (this.searchTextFieldEl.current) {
      const location = this.searchTextFieldEl.current.getBoundingClientRect();

      if (fixedSearchResults) {
        paperStyle = {
          ...paperStyle,
          position: 'fixed',
          top: location.bottom,
          left: location.left,
        };
      } else {
        paperStyle = {
          ...paperStyle,
          position: 'absolute',
        };
      }
    }

    if (initialSelectedContact) {
      return (
        <div style={{ display: 'flex' }}>
          <TextField
            fullWidth
            disabled
            value={initialSelectedContact.selectedContactDisplayName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ContactIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      );
    }

    return (
      <>
        <Downshift
          onChange={(selection, stateAndHelpers) => onContactSelect(selection, stateAndHelpers)}
          onSelect={() => {
            if (this.searchTextFieldInputEl.current) {
              this.searchTextFieldInputEl.current.blur();
            }
          }}
          itemToString={(item) => (item ? item.full_name : '')}
          onInputValueChange={debounce((newInputValue) => {
            onSearchValueChange(newInputValue);
            this.setState({ inputCopy: newInputValue });
          }, DEBOUNCE_TIME)}
          selectedItem={contactSelected}
        >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            isOpen,
            openMenu,
            inputValue,
            highlightedIndex,
            selectedItem,
            clearSelection,
          }) => (
            <div className="relative">
              <div style={{ display: 'flex' }}>
                <TextField
                  ref={(el) => (this.searchTextFieldEl.current = el)}
                  inputRef={(el) => {
                    this.searchTextFieldInputEl.current = el;
                    if (textInputRef) {
                      textInputRef.current = el;
                    }
                  }}
                  fullWidth
                  InputLabelProps={{ ...getLabelProps() }}
                  InputProps={{
                    ...getInputProps({
                      placeholder: TextFieldProps && TextFieldProps.label ? '' : 'Search Contact',
                      startAdornment: (
                        <InputAdornment position="start">
                          {selectedItem && selectedItem.id !== 'new' && selectedItem.full_name === inputValue ? (
                            <ContactEntity
                              contactId={selectedItem.id}
                              contactDisplayName={selectedItem.full_name}
                              hideDisplayName
                              onContactUpdate={onContactUpdate}
                            />
                          ) : (
                            <ContactIcon />
                          )}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{
                              padding: 4,
                              visibility:
                                selectedItem && (isFocused || isHover) && !(TextFieldProps && TextFieldProps.disabled)
                                  ? 'visible'
                                  : 'hidden',
                            }}
                            title="Clear"
                            onClick={() => clearSelection()}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                          {!disableAdvanceSearch && (
                            <IconButton
                              style={{
                                padding: 4,
                                visibility: TextFieldProps?.disabled ? 'hidden' : 'visible',
                              }}
                              title="Advanced Search"
                              onClick={() => handleAdvancedSearch(inputCopy)}
                            >
                              <Tooltip title="Advanced Search">
                                <PersonSearchIcon fontSize="small" />
                              </Tooltip>
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                      onFocus: (e) => {
                        if (selectedItem) {
                          e.target.select();
                          this.setState({ inputCopy: '' }); // so that "New Contact" will be of empty name
                          openMenu();
                          onSearchValueChange(' ');
                        }
                        this.setState({ isFocused: true });
                      },
                      onBlur: () => {
                        if (inputValue === '') clearSelection();
                        this.setState({ isFocused: false });
                      },
                      onMouseOver: () => this.setState({ isHover: true }),
                      onMouseLeave: () => this.setState({ isHover: false }),
                    }),
                  }}
                  size="small"
                  {...TextFieldProps}
                />
              </div>

              {isOpen ? (
                <Paper square style={paperStyle}>
                  {contactsListTruncated.map((contact, idx) => (
                    <Fragment key={idx}>
                      {idx === firstContactFetchedIdx && !RemoveOutOfClaimDivider && (
                        <>
                          <Divider />
                          <Typography
                            style={{ margin: '5px 0 0 16px' }}
                            color="textSecondary"
                            display="block"
                            variant="caption"
                          >
                            Not related to claim
                          </Typography>
                        </>
                      )}
                      <MenuItem
                        {...getItemProps({ item: contact, key: contact.id })}
                        selected={highlightedIndex === idx}
                        component="div"
                      >
                        <span style={{ display: 'inline-flex', alignItems: 'center', wrap: 'noWrap' }}>
                          <ListItemIcon>
                            <ContactIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <span>
                                {contact.full_name}
                                <em>{` - ${organizationContactRolesDict[contact.role]['desc']}`}</em>
                              </span>
                            }
                            secondary={<span>{contact.full_address}</span>}
                          />
                        </span>
                      </MenuItem>
                    </Fragment>
                  ))}
                  {!disallowNew && (
                    <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
                      <FsMenuItem
                        {...getItemProps({ item: { id: 'new', full_name: inputCopy } })}
                        component="div"
                        disabled={isSearchInProgress}
                      >
                        {`${inputCopy} (Create new contact)`}
                      </FsMenuItem>
                    </RestrictedPermissions>
                  )}
                  {shouldShowAllOption ? (
                    <FsMenuItem component="div" disabled={isSearchInProgress}>
                      <FsButton
                        color="primary"
                        onClick={() => {
                          onShowAllContacts(inputCopy);
                          clearSelection();
                        }}
                        className="w-full justify-center"
                      >
                        show all contacts
                      </FsButton>
                    </FsMenuItem>
                  ) : null}
                </Paper>
              ) : null}
            </div>
          )}
        </Downshift>
      </>
    );
  }
}

ContactSearch.propTypes = {
  textInputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  initialSelectedContact: PropTypes.object,
  contactSelected: PropTypes.object,
  isSearchInProgress: PropTypes.bool,
  onSearchValueChange: PropTypes.func.isRequired,
  onContactSelect: PropTypes.func.isRequired,
  contactsList: PropTypes.array,
  contact: PropTypes.object,
  isAddingContact: PropTypes.bool,
  disallowNew: PropTypes.bool,
  fixedSearchResults: PropTypes.bool,
  TextFieldProps: PropTypes.object,
  onContactUpdate: PropTypes.func,
  RemoveOutOfClaimDivider: PropTypes.bool,
  organizationContactRolesDict: PropTypes.object.isRequired,
  disableAdvanceSearch: PropTypes.bool,
  handleAdvancedSearch: PropTypes.func,
  shouldShowAllOption: PropTypes.bool,
  onShowAllContacts: PropTypes.func,
};
